import { ARAnchor, ARView } from "./AR";
import { Color, Depth, Fresnel, LayerMaterial } from "lamina";
import React, { useRef } from "react";

import { useFrame } from "@react-three/fiber";

function Box(props) {
  return (
    <mesh {...props}>
      <boxGeometry args={[1, 1, 0.1]} />
      <meshStandardMaterial color="orange" />
    </mesh>
  );
}

function Flower(props) {
  const mesh = useRef();
  const depth = useRef();
  useFrame((state, delta) => {
    mesh.current.rotation.z += delta / 2;
    depth.current.origin.set(-state.mouse.y, state.mouse.x, 0);
  });

  return (
    <mesh castShadow receiveShadow ref={mesh}>
      <torusKnotGeometry args={[0.4, 0.05, 400, 8, 3, 7]} />
      <LayerMaterial color={"#ff4eb8"} name={"Flower"}>
        <Color color={"#ff4eb8"} />
        <Depth
          far={3}
          origin={[1, 1, 1]}
          colorA="#ff00e3"
          colorB="#00ffff"
          alpha={0.5}
          mode={"multiply"}
          mapping="camera"
        />
        <Depth
          ref={depth}
          near={0.25}
          far={2}
          colorA={"#ffe100"}
          alpha={0.5}
          mode={"lighten"}
          mapping={"vector"}
        />
        <Fresnel mode={"softlight"} />
      </LayerMaterial>
    </mesh>
  );
}

function App() {
  return (
    <ARView
      targets="qr.mind"
      filterMinCF={1}
      filterBeta={10000}
      missTolerance={0}
      warmupTolerance={0}
    >
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <ARAnchor target={0}>
        <Flower />
      </ARAnchor>
    </ARView>
  );
}

export default App;
