import { Canvas, useThree } from "@react-three/fiber";
import { Matrix4, Quaternion, Vector3 } from "three";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { atom, useAtom } from "jotai";

// import { Controller as FaceTargetController } from "mind-ar/src/face-target/controller";
import { Html } from "@react-three/drei";
import { Controller as ImageTargetController } from "mind-ar/src/image-target/controller";
import Webcam from "react-webcam";
import { useUpdateAtom } from "jotai/utils";
import { useWindowSize } from "./hooks";

const anchorsAtom = atom([]);

const ARProvider = ({
  children,
  autoplay,
  targets,
  maxTrack,
  filterMinCF = null,
  filterBeta = null,
  warmupTolerance = null,
  missTolerance = null,
}) => {
  const webcamRef = useRef(null);
  const [ready, setReady] = useState(false);
  const imageControllerRef = useRef(null);
  const { camera } = useThree();
  const [anchors] = useAtom(anchorsAtom);

  const { width, height } = useWindowSize();
  const isLandscape = useMemo(() => height <= width, [height, width]);
  const ratio = useMemo(
    () => (isLandscape ? width / height : height / width),
    [isLandscape, width, height]
  );

  useEffect(() => {
    if (imageControllerRef.current) {
      const ARprojectionMatrix =
        imageControllerRef.current.getProjectionMatrix();
      camera.fov = (2 * Math.atan(1 / ARprojectionMatrix[5]) * 180) / Math.PI;
      camera.near = ARprojectionMatrix[14] / (ARprojectionMatrix[10] - 1.0);
      camera.far = ARprojectionMatrix[14] / (ARprojectionMatrix[10] + 1.0);
      camera.updateProjectionMatrix();
    }
  }, [width, height, camera]);

  const handleStream = useCallback(() => {
    if (webcamRef.current) {
      webcamRef.current.video.addEventListener("loadedmetadata", () =>
        setReady(true)
      );
    }
  }, [webcamRef]);

  const startAR = useCallback(async () => {
    const controller = new ImageTargetController({
      inputWidth: webcamRef.current.video.videoWidth,
      inputHeight: webcamRef.current.video.videoHeight,
      maxTrack,
      filterMinCF,
      filterBeta,
      missTolerance,
      warmupTolerance,
    });

    const { dimensions: imageTargetDimensions } =
      await controller.addImageTargets(targets);

    const postMatrices = imageTargetDimensions.map(
      ([markerWidth, markerHeight]) =>
        new Matrix4().compose(
          new Vector3(
            markerWidth / 2,
            markerWidth / 2 + (markerHeight - markerWidth) / 2
          ),
          new Quaternion(),
          new Vector3(markerWidth, markerWidth, markerWidth)
        )
    );

    const ARprojectionMatrix = controller.getProjectionMatrix();
    camera.fov = (2 * Math.atan(1 / ARprojectionMatrix[5]) * 180) / Math.PI;
    camera.near = ARprojectionMatrix[14] / (ARprojectionMatrix[10] - 1.0);
    camera.far = ARprojectionMatrix[14] / (ARprojectionMatrix[10] + 1.0);
    camera.updateProjectionMatrix();

    controller.onUpdate = (data) => {
      if (data.type === "updateMatrix") {
        const { targetIndex, worldMatrix } = data;

        anchors.forEach(({ anchor, target }) => {
          if (target === targetIndex) {
            anchor.visible = worldMatrix !== null;

            if (worldMatrix !== null) {
              anchor.matrix = new Matrix4()
                .fromArray([...worldMatrix])
                .multiply(postMatrices[targetIndex]);
            }
          }
        });
      }
    };

    await controller.dummyRun(webcamRef.current.video);

    controller.processVideo(webcamRef.current.video);

    imageControllerRef.current = controller;
  }, [
    maxTrack,
    filterMinCF,
    filterBeta,
    missTolerance,
    warmupTolerance,
    anchors,
    camera,
    targets,
  ]);

  useEffect(() => {
    if (ready && autoplay) {
      startAR();
    }
  }, [autoplay, ready, startAR]);

  return (
    <>
      <Html
        fullscreen
        zIndexRange={[-1, -1]}
        calculatePosition={() => [0, 0]}
        style={{ top: 0, left: 0 }}
      >
        <Webcam
          ref={webcamRef}
          onUserMedia={handleStream}
          height={height}
          width={width}
          videoConstraints={{
            facingMode: targets ? "environment" : "user",
            aspectRatio: ratio,
          }}
        />
      </Html>
      {children}
    </>
  );
};

const ARView = ({
  children,
  autoplay = true,
  targets,
  maxTrack,
  filterMinCF,
  filterBeta,
  warmupTolerance,
  missTolerance,
  ...rest
}) => {
  return (
    <Canvas
      style={{ position: "absolute", minWidth: "100vw", minHeight: "100vh" }}
      {...rest}
    >
      <ARProvider
        {...{
          autoplay,
          targets,
          maxTrack,
          filterMinCF,
          filterBeta,
          warmupTolerance,
          missTolerance,
        }}
      >
        {children}
      </ARProvider>
    </Canvas>
  );
};

const ARAnchor = ({ children, target }) => {
  const ref = useRef();
  const setAnchors = useUpdateAtom(anchorsAtom);

  useEffect(() => {
    if (ref.current)
      setAnchors((anchors) => [...anchors, { target, anchor: ref.current }]);
  }, [ref, setAnchors, target]);

  return (
    <group ref={ref} visible={false} matrixAutoUpdate={false}>
      {children}
    </group>
  );
};

export { ARView, ARAnchor };
